import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useState} from 'react';
import {IconContext} from 'react-icons';
import Navbar from './Components/Navbar';
import Sidenav from './Components/Sidenav';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';
import Footer from './Components/Footer';

function App() {
  const [wid, setWid] = useState('0%');
  const openSideNav = () => {
    setWid('100%')
  };
  const closeSideNav = () => {
    setWid('0%')
  };

  return (
    <div className="App">
      <BrowserRouter>
      <IconContext.Provider value={{ size: '2rem', margin: '1rem', padding: '1rem'}} >
      <Navbar openNav={openSideNav}/>
      <Sidenav width={wid} closeNav={closeSideNav}/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/portfolio" element={<Portfolio />}/>
       </Routes>
      <Footer /> 
      </IconContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;

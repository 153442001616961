import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaGithub, FaGitlab, FaLinkedin } from 'react-icons/fa'

const Home = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className='radial-bg'>
        <section>
        <div className="intro">
          <p className="text-layer1">Hi, I'm Andrew</p>
          <p className="text-layer2">Full-Stack Web Developer</p>
        
        <div className="flex-textcolumn text-layer3">
          <div>
          <img  src="images/new_profile_punch.jpg" alt="profile" />
          </div>
          <div>
          <p>I like simple but stylish website design that's responsive, user-friendly and up-to-date.</p>
          <p>I can develop frontend, backend, or both and I'm always interested in expanding my knowledge of Web Dev - so let me know what your preferred stack is!</p>
          </div>
        </div>
        </div>
        </section>

        <hr></hr>

        <section>
        <div className="skills">
          <div className='flex-textcolumn'>
            <p className="text-layer2">My skills and preferred stack</p>
            <p className='text-layer3'>Frontend</p>
              <div className='flex-textrow'>
                <p className='text-layer4 shadow'>HTML</p>
                <p className='text-layer4 shadow'>CSS</p>
                <p className='text-layer4 shadow'>JavaScript</p>
                <p className='text-layer4 shadow'>React.js</p>
                <p className='text-layer4 shadow'>Tailwind CSS</p>
              </div>
              <p className='text-layer3'>Backend</p>
              <div className='flex-textrow'>
                <p className='text-layer4 shadow'>Node.js</p>
                <p className='text-layer4 shadow'>Express.js</p>
                <p className='text-layer4 shadow'>MySQL</p>
                <p className='text-layer4 shadow'>Docker</p>
                <p className='text-layer4 shadow'>Google Cloud Platform</p>
              </div>
          </div>
        </div>
        </section>

        <hr></hr>
        
        <section>
        <div className="flex-textcolumn">
          <p className="text-layer2">Some food for thought</p>
            <blockquote>What you can do, or dream you can, begin it. Boldness has genius, magic, and power in it.</blockquote><p>- Goethe</p>
            <blockquote>What we are today comes from our thoughts of yesterday, and our present thoughts build our life of tomorrow: our life is the creation of our mind.</blockquote><p>- the Dhammapada</p>
            <p className="text-layer2">So with that in mind...what should we create?</p>

            <div className='flex-textrow'>
              <button onClick={() => navigate('/contact', {replace: true})}>Get in Touch</button>
              <button onClick={() => navigate('/portfolio', {replace: true})}>View my Work</button>
            </div>
            <br></br>
        </div>    
        </section>
        
        <div className="icons-inline">
              <div>
                <FaGithub />
                <p>@asnelgrove</p>
              </div>
              <div>
                <FaGitlab />
                <p>@asnel</p>
              </div>
              <div>
              <a href="http://ca.linkedin.com/in/andrew-snelgrove" target="_blank" rel="noreferrer"><FaLinkedin /></a>
              <p>Connect with me</p>
              </div>
            </div>
        
      </div>
    </>
  )
}

export default Home
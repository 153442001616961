import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = (props) => {
  return (
    <div>
        <nav className='navbar'>
          <p>Andrew Snelgrove </p>
          <div className='navbar-links'>  
            <NavLink to={'/home'} className='navbar-link'>About</NavLink>
            <NavLink to={'/contact'} className='navbar-link'>Contact</NavLink>
            <NavLink to={'/portfolio'} className='navbar-link'>My Portfolio</NavLink>
          </div>
          <div className='barcontainer' onClick={props.openNav}>
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>
          </div>
        </nav>
    </div>
  )
}

export default Navbar
import React from 'react'
import { Link } from 'react-router-dom'

const Sidenav = (props) => {
  return (
    <div className='sidenav' style={{width: props.width}}>
      <button className='close'onClick={props.closeNav}>&times;</button>
      <Link className='sidelink' to='/home' onClick={props.closeNav}>Home</Link>
      <Link className='sidelink' to='/contact' onClick={props.closeNav}>Contact</Link>
      <Link className='sidelink' to='/portfolio' onClick={props.closeNav}>My Portfolio</Link>
    </div>
  )
}

export default Sidenav
import React, {useRef, useState} from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const form = useRef();
    const [sent, setSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm(
      'service_52zfaan', 
      'template_17dtdpq', 
      form.current, 
      'mSA3F7QI8JQ-6NNMJ')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          setSent(true);
      }, (error) => {
          console.log(error.text);
      });
    };

    function handleOnChange(value) {
      console.log("Captcha value:", value);
      setIsVerified(true);
    }

  return (
    <div>
      <div className="radial-bg">
                  <div className='single-column'>  
                    <div>
                        <p>Drop me a line!</p>
                        <p>What can I do for you? What can we create?</p> 
                        <p>Fill out this contact form and I'll be in touch.</p>
                    </div>  
    
                    <form name="contactForm" ref={form} onSubmit={sendEmail}>
                       <div className="row">
                            <div className="col-25">
                                <label htmlFor="username">Name</label>
                            </div>
                           <div className="col-75">
                                <input type="text" name="user_name" id="name" required />
                            </div>     
                            
                        </div>
    
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="email address">Email Address</label>
                            </div>
                            <div className="col-75">
                                <input type="email" name="user_email" id="email" required />
                            </div>      
                            
                        </div>
    
                        <div className="row">
                           <div className="col-25">    
                                <label htmlFor="message">Message</label>
                            </div>
                            <div className="col-75">
                                <textarea rows="7" cols="50" name="message" id="message"  required  
                                ></textarea>
                            </div>
                        </div>  

                        <ReCAPTCHA
                          sitekey='6Lcq2isiAAAAAAmD22Y22OKkNfqYMt8gTyAhgCp6'
                          onChange={handleOnChange}
                        />
    
                        <div className="row">
                            <input type="submit" value="Send" id="submit" disabled={!isVerified}/>
                        </div>
                        <div style={sent ? {display:"block"} : {display:"none"}}>
                          <p style={{fontSize: '1.25rem', marginBottom: '1rem'}}>Thanks! Your message has been sent.</p>
                        </div>
                    </form>
                  </div>
    
                </div>
    </div>
  )
};

export default Contact
import { FaGithub, FaGitlab, FaLinkedin } from 'react-icons/fa'

const Portfolio = () => {

  return (
    <div className='dark-bg'>
      <div className='intro'>
        <p className='text-layer1'>Web Projects I have worked on</p>
        <div className='flex-textrow'>
          <div className='logo-box'>
            <img src="images/nomie_logo.png" alt='logo of Nomie.ca'></img>
            <p>Nomie.ca</p>
          </div>
        </div>
      </div>
      <div className='intro'>
        <p className='text-layer1'>Sample projects and practice websites</p>
        <div className='flex-textrow'>

          <a href="https://baskitt-a0fab.firebaseapp.com/" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Baskitt</p>
              <p>shopping list app with React and Firebase</p>
            </div>
          </a>

          <a href="https://asnelgrove.github.io/Quiz-App-React/" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Quiz App</p>
              <p>trivia game made with React</p>
            </div>
          </a>

          <a href="https://art-portfolio-55b37.firebaseapp.com//" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>My Drawing Portfolio</p>
              <p>my art on display in React</p>
            </div>
          </a>

          <a href="https://asnelgrove.github.io/tours-react/" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Tempus Tours</p>
              <p>mock ecommerce website made with React</p>
            </div>
          </a>

          <a href="https://asnelgrove.github.io/planet-wanderer/" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Planet Wanderer</p>
              <p>mock travel magazine landing-page styled with Tailwind CSS</p>
            </div>
          </a>

          <a href="https://asnelgrove.github.io/breakout-game/" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Breakout Game</p>
              <p>in Vanilla JavaScript</p>
            </div>
          </a>

          <a href="https://asnelgrove.github.io/xmas-animation" target="_blank" rel="noreferrer">
            <div className='outline-box'>
              <p>Clickable Holiday Animation</p>
              <p>SVGs come to life with JavaScript</p>
            </div>
          </a>

          {/* <a href="https://asnelgrove.github.io/text-portrait/" target="_blank" rel="noreferrer"><div className='outline-box'>Text-portrait effect with CSS</div></a> */}
        </div>
      </div>
      <div className="icons-inline">
        <div>
          <FaGithub />
          <p>@asnelgrove</p>
        </div>
        <div>
          <FaGitlab />
          <p>@asnel</p>
        </div>
        <div>
          <a href="http://ca.linkedin.com/in/andrew-snelgrove" target="_blank" rel="noreferrer"><FaLinkedin /></a>
          <p>Connect with me</p>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
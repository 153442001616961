import React from 'react'

const Footer = () => {
 
  return (
    <div className='footer'>
       <p>&copy; <span>{(new Date().getFullYear())}</span> Andrew Snelgrove All rights reserved.</p> 
    </div>
  )
}

export default Footer